<template>
    <div class="general">
        <div class="header">
            <div class="container clearfix">
                <div class="logo-box">
                    <router-link to="/"></router-link>
                </div>
                <div class="right-btn">
                    <router-link to="/" title="首页"><i class="el-icon-circle-close"></i></router-link>
                </div>
            </div>
        </div>
        <div class="general-main container">
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-card class="date-bar">
                        <p>资产概况（<span>{{date}}</span>）</p>
                    </el-card>
                </el-col>
            </el-row>
            <el-row :gutter="20" class="chart-row mt20">
                <el-col :span="8">
                    <el-card>
                        <div slot="header" class="clearfix">
                            <span>资产统计</span>
                        </div>
                        <p class="worth-title">累计资产</p>
                        <p class="worth-total">￥{{assetTotal}}</p>
                        <div class="card-footer">
                            <el-row :gutter="20">
                                <el-col :span="8">
                                    <p>{{accountCount}}</p>
                                    <p class="detail-title">账户个数</p>
                                </el-col>
                                <el-col :span="8">
                                    <p>{{totalProfit}}</p>
                                    <p class="detail-title">累计盈利</p>
                                </el-col>
                                <el-col :span="8">
                                    <p>{{todayProfit}}</p>
                                    <p class="detail-title">今日盈亏</p>
                                </el-col>
                            </el-row>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="8">
                    <el-card>
                        <div slot="header" class="clearfix">
                            <span>资产结构</span>
                        </div>
                        <h-chart id="structChart" :options="structOptions"></h-chart>
                    </el-card>
                </el-col>
                <el-col :span="8">
                    <el-card>
                        <div slot="header" class="clearfix">
                            <span>账户盈亏</span>
                        </div>
                        <h-chart id="profitChart" :options="profitOptions"></h-chart>
                    </el-card>
                </el-col>
            </el-row>
            <!--<el-row :gutter="20" class="chart-row mt20">
                <el-col :span="8">
                    <el-card>
                        <div slot="header" class="clearfix">
                            <span>净值曲线</span>
                        </div>
                        <h-chart id="profitCurveChart" :options="profitCurveOptions"></h-chart>
                    </el-card>
                </el-col>
                <el-col :span="8">
                    <el-card>
                        <div slot="header" class="clearfix">
                            <span>持仓情况</span>
                        </div>
                        <h-chart id="positionChart" :options="positionOptions"></h-chart>
                    </el-card>

                </el-col>
                <el-col :span="8">
                    <el-card>
                        <div slot="header" class="clearfix">
                            <span>持仓周期</span>
                        </div>
                        <h-chart id="tradeChart" :options="tradeOptions"></h-chart>
                    </el-card>
                </el-col>
            </el-row>-->
        </div>
    </div>
</template>

<script>
  import HChart from '@/components/HChart.vue'
  import {Loading} from "element-ui";
  import {apiGeneral} from '../api/analysis'

  export default {
    name: "General",
    data () {
      return {
        date: '',
        assetTotal: 0,
        totalProfit: 0,
        todayProfit: 0,
        structOptions: {
          credits: {
            enabled: false
          },
          chart: {
            type: 'pie',
            options3d: {
              enabled: true,
              alpha: 45,
              beta: 0
            }
          },
          colors: ['#EB3E41', '#FC8F22', '#229AFC', '#4FCB77', '#3922FC', '#9C22FC'],
          title: false,
          tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
          },
          legend: false,
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              depth: 35,
              dataLabels: {
                enabled: true,
                format: '{point.name}'
              }
            }
          },
          series: []
        },
        profitOptions: {
          credits: {
            enabled: false
          },
          chart: {
            renderTo: 'container',
            type: 'column',
            options3d: {
              enabled: true,
              alpha: 5,
              beta: 5,
              depth: 30,
              viewDistance: 90
            }
          },
          title: false,
          tooltip: {
            pointFormat: '{series.name}: <b>{point.y:.2f}</b>'
          },
          legend: false,
          colors: ['#EB3E41'],
          // plotOptions: {
          //     series: {
          //         pointWidth: 50
          //     }
          // },
          xAxis: {
            categories: []
          },
          yAxis: {
            title: false,
          },
          series: [],
        },
        positionOptions: {},
        tradeOptions: {},
        profitCurveOptions: {},
      }
    },
    components: {
      HChart
    },
    computed: {
      accountCount: function () {
        return this.$store.state.accounts.length
      }
    },
    methods: {
      async getData () {
        if (this.$store.state.accounts.length === 0) {
          this.$message({
            message: '请先添加期货监控中心账户或等待数据更新',
            type: 'warning'
          });
          this.$router.push({name: 'Account'});
          return false;
        }
        let loadingInstance = Loading.service({'target': '#structChart'});
        let res = await apiGeneral()
        if (res.data.code === 0 && res.data.data) {
          const resData = res.data.data
          const {asset_total,today_profit,total_profit,asset_struct,account_profit} = resData
          let categories = []
          let seriesData = []
          let pieData = []
          for (const key in account_profit) {
            const userAccount = this.$store.state.accounts.filter(item => item.id === Number(key))
            if (userAccount[0]) {
              categories.push(userAccount[0].nickname)
              seriesData.push(account_profit[key])
            }
          }
          for (const key in asset_struct) {
            const userAccount = this.$store.state.accounts.filter(item => item.id === Number(key))
            if (userAccount[0]) {
              pieData.push(
                [
                  userAccount[0].nickname,
                  asset_struct[key]
                ])
            }
          }
          this.date = 'date';
          this.assetTotal = asset_total;
          this.todayProfit = today_profit;
          this.totalProfit = total_profit;
          let structOptions = JSON.parse(JSON.stringify(this.structOptions));
          structOptions.series = [
            {
              type: 'pie',
              name: '占比',
              radius: ['30%', '70%'],
              data: pieData
            }
          ];
          this.structOptions = structOptions;

          let profitOptions = JSON.parse(JSON.stringify(this.profitOptions));
          profitOptions.xAxis.categories = categories;
          profitOptions.series = [
            {
              name: '盈亏',
              data: seriesData
            }
          ];
          this.profitOptions = profitOptions;
        }
        loadingInstance.close()
      }
    },
    mounted () {
      this.getData()
    }
  }
</script>

<style lang="scss">
    .general {
        .date-bar {
            p {
                margin: 0;
                text-align: left;
            }
        }

        .mt20 {
            margin-top: 20px;
        }

        .header {
            .logo-box {
                width: 600px;
                float: left;
                text-align: left;
                padding-top: 9px;

                a {
                    display: inline-block;
                    width: 216px;
                    height: 38px;
                    background-image: url("../assets/qiweihu_logo.png");
                    background-size: 216px 38px;
                    float: left;
                }

                span {
                    line-height: 45px;
                    margin-left: 10px;
                    font-size: 14px;
                }
            }

            .right-btn {
                float: right;

                i {
                    font-size: 38px;
                    color: #666666;
                    line-height: 60px;
                }
            }
        }

        .general-main {
            margin-top: 20px;

            .chart-row {
                .el-card {
                    height: 290px;
                    border: 0;

                    p {
                        margin-bottom: 0;
                    }

                    .el-card__header {
                        background: #D25400;
                        color: #FFFFFF;
                    }

                    .worth-title {
                        font-weight: bold;
                        font-size: 20px;
                    }

                    .worth-total {
                        font-size: 30px;
                        color: #3a8ee6;
                    }

                    .card-footer {
                        margin-top: 30px;

                        p {
                            font-size: 14px;
                            line-height: 24px;
                            margin: 0;

                            &.detail-title {
                                color: #888888;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }

            #structChart {
                height: 180px;
            }

            #profitChart {
                height: 200px;
            }
        }
    }
</style>
